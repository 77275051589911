import { Button, Form, Input } from "antd";
import { useEffect, useState } from "react";
import { user } from "../../services/user";
import UploadComponent from "../../shared/upload";

const LoginForm = ({ onLogin, fileList, setFileList }) => {
  const [form] = Form.useForm();
  console.log(user, "ddd");
  useEffect(() => {
    form.setFieldsValue(user);
  }, [form]);
  useEffect(() => {
    setFileList([
      {
        uid: "-1",
        name: "image.png",
        status: "done",
        url: user.photo_url,
      },
    ]);
  }, [setFileList]);
  return (
    <Form
      name="login-form"
      onFinish={onLogin}
      className="login-form"
      form={form}
    >
      <UploadComponent fileList={fileList} setFileList={setFileList} />
      <div style={{ display: "flex", gap: "10px" }}>
        <Form.Item
          name="first_name"
          rules={[
            {
              required: true,
              message: "Please Enter First Name!",
            },
          ]}
          style={{ width: "50%" }}
        >
          <Input
            type="text"
            placeholder="First Name"
            style={{ height: "45px" }}
          />
        </Form.Item>
        <Form.Item
          name="last_name"
          rules={[
            {
              required: true,
              message: "Please Enter Last Name!",
            },
          ]}
          style={{ width: "50%" }}
        >
          <Input
            type="text"
            placeholder={"Last Name"}
            style={{ height: "45px" }}
          />
        </Form.Item>
      </div>
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: "Please enput your Email",
          },
          {
            type: "text",
            message: "Please enter a validate Email!",
          },
        ]}
      >
        <Input
          placeholder="Email Address"
          style={{ height: "45px", width: "100%" }}
        />
      </Form.Item>
      <Form.Item
        name="mobile"
        rules={[
          {
            required: true,
            message: "Please Enter mobile number!",
          },
        ]}
      >
        <Input
          type="number"
          placeholder={"Mobile"}
          style={{ height: "45px" }}
        />
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          block
          style={{
            background: "#00A8C0",
            borderRadius: "6px",
            height: "55px",
            color: "#EFF4F8",
            fontWeight: "600",
            lineHeight: "14px",
            marginTop: "5px",
          }}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};
export default LoginForm;
