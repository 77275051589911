import { Row, Col } from "antd";
import LoginForm from "./form";
import { FormContainer, LoginContainer, SignInText, FillYour } from "./style";

const Otp = () => {
  return (
    <div style={{ height: "100vh", overflow: "hidden" }}>
      <Row
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Col style={{ width: "50%" }} className="left-col">
          <LoginContainer>
            <FormContainer
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src="logo.svg" alt="logo" />
              <SignInText>OTP Message</SignInText>
              <FillYour>Please enter the OTP Which sent to you!</FillYour>
              <LoginForm />
              <span className="no-otp">
                No OTP Sent? <span>Send again</span>
              </span>
            </FormContainer>
          </LoginContainer>
        </Col>
        <Col xs={0} sm={12} style={{ height: "100%", width: "100%" }}>
          <img
            alt=""
            src="login-cover.jpeg"
            style={{
              height: "100%",
              backgroundRepeat: "no-repeat",
              objectFit: "cover",
              width: "100%",
            }}
          />
        </Col>
      </Row>
    </div>
  );
};
export default Otp;
