const protectedPages = [
  "dashboard",
  "items",
  "order-summary",
  "order-track",
  "my-requests",
  "complaints",
  "announcements",
  "notification",
  "invoices",
];
const requestView = { path: "/view/request/:id", filePath: "order-track" };
const complaintsView = {
  path: "/complaints-details/:id",
  filePath: "complaints-details",
};
export const protectedRoutes = [
  requestView,
  complaintsView,
  ...protectedPages.map((p) => ({ path: p, filePath: p })),
];
