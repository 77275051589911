import "./App.css";

import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Spin } from "antd";

import { Suspense, lazy } from "react";
import { protectedRoutes } from "./constants/routes";
import Login from "./components/login";
import ForgetPass from "./components/forget-pass";
import ResetPass from "./components/reset-pass";
import Otp from "./components/otp";
import EditProfile from "./components/edit-profile";
const importComponent = (path) => {
  return lazy(() => import(`./${path}`));
};
const protectedRouteComponents = protectedRoutes.map(({ path, filePath }) => ({
  path: `/${path.toLowerCase()}`,
  component: importComponent(`components/${filePath}`),
}));
function App() {
  const isAuthenticated = localStorage.getItem("token") !== null;

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {isAuthenticated &&
            protectedRouteComponents.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={
                  <Suspense
                    fallback={
                      <div className="center-spin">
                        <Spin tip="Loading" size="large" />
                      </div>
                    }
                  >
                    <route.component />
                  </Suspense>
                }
              />
            ))}
          <Route path="/login" element={<Login />} />
          <Route path="/verify" element={<Otp />} />
          <Route path="/forget-password" element={<ForgetPass />} />
          <Route path="/reset-password" element={<ResetPass />} />
          <Route path="/edit-profile" element={<EditProfile />} />
          {isAuthenticated ? (
            <Route
              path="*"
              exact={true}
              element={<Navigate replace to="/dashboard" />}
            />
          ) : (
            <Route
              path="*"
              exact={true}
              element={<Navigate replace to="/login" />}
            />
          )}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
