import { Row, message, Form } from "antd";
import LoginForm from "./form";
import { FormContainer, LoginContainer, SignInText, FillYour } from "./style";
import customFetch from "../../services/api";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { user } from "../../services/user";

const Login = () => {
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const onFinish = async (values) => {
    let formData = { ...values, _method: "PUT", company_id: user.company_id };
    if (!fileList[0].url) {
      formData = new FormData();
      formData.append("photo", fileList[0]);
      formData.append("_method", "PUT");
      formData.append("company_id", user.company_id);

      setLoading(true);
      for (const key in values) {
        if (values.hasOwnProperty(key)) {
          formData.append(key, values[key]);
        }
      }
    }
    const res = await customFetch("users/update", {
      method: "POST",
      body: formData,
    });
    if (res.status) {
      message.success({
        content: "Profile has been updated successfully",
        duration: 2,
      });
      localStorage.setItem("user", JSON.stringify(res.data));
      navigate("/dashboard");
      window.location.reload();
    } else {
      form.setFields(
        Object.entries(res.data).map(([fieldName, errors]) => ({
          name: fieldName,
          errors: errors,
        }))
      );
      message.error({
        content: "an error has been occured",
        duration: 2,
      });
    }
  };
  return (
    <div style={{ width: "100%" }}>
      <Link to={"/dashboard"} className="back-to-login">
        <img src="arrow.svg" alt="arrow.png" />
        Back to Dashboard
      </Link>
      <Row
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          margin: "0",
        }}
      >
        <LoginContainer>
          <FormContainer>
            <img src="logo.svg" alt="logo" />
            <SignInText>Edit Profile</SignInText>
            <FillYour style={{ width: "390px", marginBottom: "14px" }}>
              Please enter your new Data
            </FillYour>
            <LoginForm
              onLogin={onFinish}
              fileList={fileList}
              setFileList={setFileList}
            />
          </FormContainer>
        </LoginContainer>
      </Row>
    </div>
  );
};
export default Login;
