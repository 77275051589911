import { Row, Col, message } from "antd";
import LoginForm from "./form";
import { FormContainer, LoginContainer, SignInText, FillYour } from "./style";
import customFetch from "../../services/api";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Login = () => {
  const navigate = useNavigate();
  const email = useSelector((state) => state.email);
  const onFinish = async (values) => {
    try {
      const response = await customFetch("change-password", {
        body: { ...values, code: "0000", email },
        method: "POST",
      });
      if (response.status) {
        navigate("/login");
      } else {
        message.error("Email is invalid");
      }
    } catch (error) {
      message.error("Email is invalid");
    }
  };
  return (
    <div style={{ height: "100vh", overflow: "hidden" }}>
      <Link to={"/login"} className="back-to-login">
        <img src="arrow.svg" alt="arrow.png" />
        Back to login
      </Link>
      <Row
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Col style={{ width: "50%" }} className="left-col">
          <LoginContainer>
            <FormContainer
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src="logo.svg" alt="logo" />
              <SignInText>Reset Password</SignInText>
              <FillYour>Reset Password</FillYour>
              <LoginForm onLogin={onFinish} />
            </FormContainer>
          </LoginContainer>
        </Col>
        <Col xs={0} sm={12} style={{ height: "100%", width: "100%" }}>
          <img
            alt=""
            src="login-cover.jpeg"
            style={{
              height: "100%",
              backgroundRepeat: "no-repeat",
              objectFit: "cover",
              width: "100%",
            }}
          />
        </Col>
      </Row>
    </div>
  );
};
export default Login;
