import { UploadOutlined } from "@ant-design/icons";
import { Upload, Button, message } from "antd";

const UploadComponent = ({
  fileList,
  setFileList,
  maxCount = 10,
  listType = "picture-circle",
  uploadMoreThanOne = false,
  title,
}) => {
  const maxFileSize = 5;

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const isImage = file.type.startsWith("image/");
      if (!isImage) {
        message.error("You can only upload image files!");
        return false;
      }

      const isSizeValid = file.size / 1024 / 1024 < maxFileSize;
      if (!isSizeValid) {
        message.error(`Image must be smaller than ${maxFileSize}MB!`);
        return false;
      }

      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };
  return (
    <>
      <Upload
        {...props}
        listType={listType}
        className="avatar-uploader"
        showUploadList={{ showPreviewIcon: true }}
        maxCount={maxCount}
        accept="image/png, image/jpeg"
        defaultFileList={fileList ? [...fileList] : []}
        style={{ width: "100%" }}
      >
        {fileList && (fileList.length === 0 || uploadMoreThanOne) && (
          <Button style={{ width: "100%" }} icon={<UploadOutlined />}>
            {title || "Select Image"}
          </Button>
        )}
      </Upload>
    </>
  );
};
export default UploadComponent;
